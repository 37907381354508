import React, { useRef, useEffect } from "react";
import ReactImageFallback from "react-image-fallback";
import currencyFormatter from "currency-formatter";
import "./LookBook.scss";

export default function LookBook(props) {
  const {
    basketDetails,
    cartDetails,
    showDiaImage,
    showZoomImage,
    diaIcon,
    thumbnailImage,
    includeGIA,
    includeKTDShapes,
    items,
    includeStoneValues,
    includeLocation,
    priceLabel,
    handleImage,
    includeSummary,
    basketInputObj,
    includeAssetOrMemo,
    // isMultipleValueEmpty
  } = props;
  // console.log("cartDetails: ", cartDetails);
  // console.log("includeStone: ", includeStoneValues);
  const summaryElement = useRef();

  let applyTopMargin = true;
  let topMarginItemcount = 0;

  const PageTemplate = (
    <div
      style={{
        position: "absolute",
        top: "10px",
        left: "10px",
      }}
    >
      {basketDetails.desc}
    </div>
  );

  const getSummary = () => {
    let elementSummary = document.querySelector(".basket__summary");
    console.log(
      "elementSummary :",
      elementSummary,
      "\n elementSummary type :",
      typeof elementSummary
    );
    let duplicateElementSummary = elementSummary.cloneNode(true);
    if (basketInputObj && !basketInputObj.includeRetail) {
      let nodeToBeRemoved = duplicateElementSummary.querySelector(
        ".summary__info.summary__retail-price"
      );
      console.log("nodeTOBeRemoved", nodeToBeRemoved);
      console.log(
        "last Element Child :",
        duplicateElementSummary.lastElementChild,
        "\n last element child type :",
        typeof duplicateElementSummary.lastElementChild
      );
      nodeToBeRemoved.style.display = "none";
      // duplicateElementSummary =
      // duplicateElementSummary.lastElementChild.remove(nodeToBeRemoved);
      // nodeToBeRemoved.parentNode.remove(nodeToBeRemoved);
    }
    console.log(
      "duplicateElementSummary :",
      duplicateElementSummary,
      "\n duplicateElementSummary type :",
      typeof duplicateElementSummary
    );
    summaryElement &&
      summaryElement.current.appendChild(duplicateElementSummary);
    // return elementSummary;
  };

  useEffect(() => {
    if (includeSummary) {
      getSummary();
    }
  }, [includeSummary]);

  // const handleImage = (item) => {
  //   if (item.WebImage1) {
  //     //   console.log(item);
  //     let str = item.WebImage1.replace(".jpg", "-product@2x.jpg");
  //     let imageurl = "https://cdn.kwiat.com/source-images/web/product/" + str;
  //     return imageurl;
  //   } else if (item.LargeImageName) {
  //     let searchimage;
  //     searchimage = item.LargeImageName;
  //     let str = searchimage.split("\\");
  //     searchimage = str[str.length - 1];
  //     let imageurl = "https://cdn.kwiat.com/source-images/large/" + searchimage;
  //     return imageurl;
  //   } else if (
  //     (item.productType === "D" || item.productType === "J") &&
  //     item.Shape
  //   ) {
  //     let imageurl =
  //       "https://cdn.kwiat.com/apps/kwiat-elastic-search/dia-shapes/" +
  //       item.Shape +
  //       ".svg";
  //     return imageurl;
  //   } else {
  //     let imageurl =
  //       "https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Missing-Images-Final-100x75px-01.svg";
  //     return imageurl;
  //   }
  // };

  return (
    <div className="layout_second">
      {/* <div className="header_description">{basketDetails.desc}</div> */}
      <div
        className="item_container"
        style={
          cartDetails.length <= 3 && items === "6"
            ? { height: "600px", columnGap: "9px" }
            : // : cartDetails.length <= 3
            // ? { height: "600px" }
            items === "6"
            ? { columnGap: "9px" }
            : {}
        }
      >
        {cartDetails &&
          cartDetails.map((item, index) => {
            // console.log({ topMarginItemcount, applyTopMargin });
            // if (index % items === 0) {
            //   applyTopMargin = true;
            // } else if (topMarginItemcount > 3) {
            //   // console.log("Inside false");
            //   applyTopMargin = false;
            //   topMarginItemcount = 0;
            // }
            // if (applyTopMargin === true) {
            //   topMarginItemcount = topMarginItemcount++;
            // }
            let url;
            if (includeGIA === "Yes" && item.ReportJpgUrls) {
              if (item.ReportJpgUrls.includes("|")) {
                if (item.ReportJpgUrls.charAt(0) === " ") {
                  url = item.ReportJpgUrls.replace(/ /, "").split("|");
                } else {
                  url = item.ReportJpgUrls.split("|");
                }
              } else {
                url = [item.ReportJpgUrls];
              }
            }
            return (
              <div
                className={
                  item.transformType === "DiamondSerial"
                    ? "item"
                    : "item_jewellry"
                }
                key={item.Shape + index}
                // style={
                //   items === "6" && applyTopMargin && topMarginItemcount < 4
                //     ? { width: "240px", height: "190px", marginTop: "0.75in" }
                //     : applyTopMargin && topMarginItemcount < 4
                //     ? { marginTop: "0.75in" }
                //     : {}
                // }
                style={items === "6" ? { width: "240px", height: "190px" } : {}}
              >
                <div
                  className={
                    item.transformType === "DiamondSerial"
                      ? "item_icon"
                      : "item_icon_jewellry"
                  }
                  style={items === "6" ? { height: "60%" } : {}}
                >
                  {basketDetails.includeLinks === "Web or Internal Imagery" &&
                  item.webProductURL &&
                  item.webProductURL !== "" &&
                  item.webProductURL !== null &&
                  ((item.linkVisibility && item.linkVisibility === "Default") ||
                    !item.linkVisibility) ? (
                    <a href={item.webProductURL} target="_blank">
                      <img
                        src={handleImage(item)}
                        onError={(event) => {
                          event.target.src =
                            "https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Missing-Images-Final-100x75px-01.svg";
                        }}
                      />
                    </a>
                  ) : basketDetails.includeLinks === "ONLY Web Imagery" &&
                    item.hasWebImage &&
                    item.hasWebImage === "1" &&
                    item.webProductURL &&
                    item.webProductURL !== "" &&
                    item.webProductURL !== null &&
                    ((item.linkVisibility &&
                      item.linkVisibility === "Default") ||
                      !item.linkVisibility) ? (
                    <a href={item.webProductURL} target="_blank">
                      <img
                        src={handleImage(item)}
                        onError={(event) => {
                          event.target.src =
                            "https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Missing-Images-Final-100x75px-01.svg";
                        }}
                      />
                    </a>
                  ) : (
                    <a href={handleImage(item)} target="_blank">
                      <img
                        src={handleImage(item)}
                        onError={(event) => {
                          event.target.src =
                            "https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Missing-Images-Final-100x75px-01.svg";
                        }}
                      />
                    </a>
                  )}
                </div>

                {(!item.transformType ||
                  item.transformType === "JewelrySerial") &&
                item.Description ? (
                  <div className="item_serial_description">
                    {item.Description}
                  </div>
                ) : (
                  ``
                )}
                {item.SerialNumber || item.StyleNumber ? (
                  <div
                    className={
                      item.transformType === "DiamondSerial" ||
                      item.transformType === "GemstoneSerial"
                        ? "item_daimond_description"
                        : "item_serial_style_no"
                    }
                  >
                    {item.transformType === "JewelrySerial" ||
                    !item.transformType
                      ? item.StyleNumber && item.SerialNumber
                        ? item.StyleNumber + " | " + item.SerialNumber
                        : item.StyleNumber
                        ? item.StyleNumber
                        : item.SerialNumber
                        ? item.SerialNumber
                        : ``
                      : item.transformType === "DiamondSerial" ||
                        // item.transformType === "JewelrySerial" ||
                        !item.transformType
                      ? item.SerialNumber && item.ShapeDisplay
                        ? item.ShapeDisplay + " | " + item.SerialNumber
                        : item.ShapeDisplay
                        ? item.ShapeDisplay
                        : item.ShapeDisplay
                        ? item.ShapeDisplay
                        : ``
                      : item.transformType === "GemstoneSerial"
                      ? item.GemstoneType && item.Shape && item.SerialNumber
                        ? item.GemstoneType +
                          " " +
                          item.Shape +
                          " | " +
                          item.SerialNumber
                        : item.GemstoneType && item.Shape
                        ? item.GemstoneType + " " + item.Shape
                        : item.Shape && item.SerialNumber
                        ? item.Shape + " | " + item.SerialNumber
                        : item.GemstoneType && item.SerialNumber
                        ? item.GemstoneType + " | " + item.SerialNumber
                        : ``
                      : ``}
                    {basketDetails.includeLinks === "Web or Internal Imagery" &&
                    item.webProductURL &&
                    item.webProductURL !== "" &&
                    item.webProductURL !== null &&
                    ((item.linkVisibility &&
                      item.linkVisibility === "Default") ||
                      !item.linkVisibility) ? (
                      <a href={item.webProductURL}> view online</a>
                    ) : basketDetails.includeLinks === "ONLY Web Imagery" &&
                      item.hasWebImage &&
                      item.hasWebImage === "1" &&
                      item.webProductURL &&
                      item.webProductURL !== "" &&
                      item.webProductURL !== null &&
                      ((item.linkVisibility &&
                        item.linkVisibility === "Default") ||
                        !item.linkVisibility) ? (
                      <a href={item.webProductURL}> view online</a>
                    ) : (
                      ``
                    )}
                  </div>
                ) : (
                  ``
                )}
                {item.transformType === "DiamondSerial" ? (
                  item.IsRough === "1" && item.CertReceived=== "0" ? (
                    <div className="item_details">
                      {item.DiamondCaratWeight &&
                      item.MTSDiamondColorRange &&
                      item.MTSDiamondClarityRange ? (
                        <div>
                          {Number(item.DiamondCaratWeight).toFixed(2)} cts{" "}
                          {item.MTSDiamondColorRange} Color/
                          {item.MTSDiamondClarityRange} Clarity{" "}
                          {/*  {(includeGIA === "Yes" ||
                            includeKTDShapes === "Yes") && (
                            <a href={url} target="_blank">
                              (view report)
                            </a>
                            )}*/}
                        </div>
                      ) : item.DiamondCaratWeight &&
                        item.MTSDiamondColorRange ? (
                        <div className="item_details">
                          {Number(item.DiamondCaratWeight).toFixed(2)} cts{" "}
                          {item.MTSDiamondColorRange} Color{" "}
                          {/*  {(includeGIA === "Yes" ||
                            includeKTDShapes === "Yes") && (
                            <a href={url} target="_blank">
                              (view report)
                            </a>
                            )}*/}
                        </div>
                      ) : item.MTSDiamondColorRange &&
                        item.MTSDiamondClarityRange ? (
                        <div className="item_details">
                          {item.MTSDiamondColorRange} Color/
                          {item.MTSDiamondClarityRange} Clarity{" "}
                          {/*  {(includeGIA === "Yes" ||
                            includeKTDShapes === "Yes") && (
                            <a href={url} target="_blank">
                              (view report)
                            </a>
                            )}*/}
                        </div>
                      ) : item.DiamondCaratWeight &&
                        item.MTSDiamondClarityRange ? (
                        <div className="item_details">
                          {Number(item.DiamondCaratWeight).toFixed(2)} cts
                          {item.MTSDiamondClarityRange} Clarity{" "}
                          {/*  {(includeGIA === "Yes" ||
                            includeKTDShapes === "Yes") && (
                            <a href={url} target="_blank">
                              (view report)
                            </a>
                            )}*/}
                        </div>
                      ) : item.DiamondCaratWeight ? (
                        <div className="item_details">
                          {Number(item.DiamondCaratWeight).toFixed(2)} cts{" "}
                          {/*  {(includeGIA === "Yes" ||
                            includeKTDShapes === "Yes") && (
                            <a href={url} target="_blank">
                              (view report)
                            </a>
                            )}*/}
                        </div>
                      ) : item.MTSDiamondColorRange ? (
                        <div className="item_details">
                          {item.MTSDiamondColorRange} Color{" "}
                          {/*  {(includeGIA === "Yes" ||
                            includeKTDShapes === "Yes") && (
                            <a href={url} target="_blank">
                              (view report)
                            </a>
                            )}*/}
                        </div>
                      ) : item.MTSDiamondClarityRange ? (
                        <div className="item_details">
                          {item.MTSDiamondClarityRange} Clarity{" "}
                          {/*  {(includeGIA === "Yes" ||
                            includeKTDShapes === "Yes") && (
                            <a href={url} target="_blank">
                              (view report)
                            </a>
                            )}*/}
                        </div>
                      ) : (
                        ``
                      )}
                    </div>
                  ) : (
                    <>
                      {item.DiamondCaratWeight &&
                      item.DiamondColorRange &&
                      item.DiamondClarityRange ? (
                        <div className="item_details">
                          {Number(item.DiamondCaratWeight).toFixed(2)}cts{" "}
                          {item.DiamondColorRange} Color/
                          {item.DiamondClarityRange} Clarity{" "}
                          {/*  {(includeGIA === "Yes" ||
                            includeKTDShapes === "Yes") && (
                            <a href={url} target="_blank">
                              (view report)
                            </a>
                            )}*/}
                        </div>
                      ) : item.DiamondCaratWeight && item.DiamondColorRange ? (
                        <div className="item_details">
                          {Number(item.DiamondCaratWeight).toFixed(2)} cts
                          {item.DiamondColorRange} Color{" "}
                          {/*  {(includeGIA === "Yes" ||
                            includeKTDShapes === "Yes") && (
                            <a href={url} target="_blank">
                              (view report)
                            </a>
                            )}*/}
                        </div>
                      ) : item.DiamondColorRange && item.DiamondClarityRange ? (
                        <div className="item_details">
                          {item.DiamondColorRange} Color/
                          {item.DiamondClarityRange} Clarity{" "}
                          {/*  {(includeGIA === "Yes" ||
                            includeKTDShapes === "Yes") && (
                            <a href={url} target="_blank">
                              (view report)
                            </a>
                            )}*/}
                        </div>
                      ) : item.DiamondCaratWeight &&
                        item.DiamondClarityRange ? (
                        <div className="item_details">
                          {Number(item.DiamondCaratWeight).toFixed(2)} cts
                          {item.DiamondClarityRange} Clarity{" "}
                          {/*  {(includeGIA === "Yes" ||
                            includeKTDShapes === "Yes") && (
                            <a href={url} target="_blank">
                              (view report)
                            </a>
                            )}*/}
                        </div>
                      ) : item.DiamondCaratWeight ? (
                        <div className="item_details">
                          {Number(item.DiamondCaratWeight).toFixed(2)} cts{" "}
                          {/*  {(includeGIA === "Yes" ||
                            includeKTDShapes === "Yes") && (
                            <a href={url} target="_blank">
                              (view report)
                            </a>
                            )}*/}
                        </div>
                      ) : item.DiamondColorRange ? (
                        <div className="item_details">
                          {item.DiamondColorRange} Color{" "}
                          {/*  {(includeGIA === "Yes" ||
                            includeKTDShapes === "Yes") && (
                            <a href={url} target="_blank">
                              (view report)
                            </a>
                            )}*/}
                        </div>
                      ) : item.DiamondClarityRange ? (
                        <div className="item_details">
                          {item.DiamondClarityRange} Clarity{" "}
                          {/*  {(includeGIA === "Yes" ||
                            includeKTDShapes === "Yes") && (
                            <a href={url} target="_blank">
                              (view report)
                            </a>
                            )}*/}
                        </div>
                      ) : (
                        ``
                      )}
                    </>
                  )
                ) : (
                  ``
                )}
                {item.transformType === "GemstoneSerial" ? (
                  item.CaratWeight &&
                  item.CountryofOrigin &&
                  item.GemEnhancement ? (
                    <div>
                      {Number(item.CaratWeight).toFixed(2)} cts -{" "}
                      {item.CountryofOrigin} - {item.GemEnhancement}
                    </div>
                  ) : item.CaratWeight && item.CountryofOrigin ? (
                    <div>
                      {Number(item.CaratWeight).toFixed(2)} cts -{" "}
                      {item.CountryofOrigin}{" "}
                    </div>
                  ) : item.CountryofOrigin && item.GemEnhancement ? (
                    <div>
                      {item.CountryofOrigin} - {item.GemEnhancement}
                    </div>
                  ) : item.CaratWeight && item.GemEnhancement ? (
                    <div>
                      {Number(item.CaratWeight).toFixed(2)} cts -{" "}
                      {item.GemEnhancement}
                    </div>
                  ) : item.CaratWeight ? (
                    <div>{Number(item.CaratWeight).toFixed(2)} cts</div>
                  ) : item.CountryofOrigin ? (
                    <div>{item.CountryofOrigin}</div>
                  ) : item.GemEnhancement ? (
                    <div>{item.GemEnhancement}</div>
                  ) : (
                    ``
                  )
                ) : (
                  ``
                )}
                {(item.transformType === "JewelrySerial" ||
                  !item.transformType) &&
                item.Metal ? (
                  <div className="item_metal">{item.Metal}</div>
                ) : (
                  ``
                )}
                {item.InternalNote || includeLocation ? (
                  <div className="item_internal_note">
                    {item.InternalNote &&
                    item.InternalNote !== "" &&
                    includeLocation
                      ? `Notes: ${item.InternalNote} - ${
                          item.SerialNumber
                            ? item.Warehouse && item.Warehouse === "MEMO"
                              ? `${item.Warehouse} - ${item.StatusCustomer}`
                              : item.Warehouse
                            : ""
                        }
                      ${
                        item.SerialNumber
                          ? item.Warehouse !== item.SerialStatus &&
                            item.Warehouse &&
                            item.SerialStatus
                            ? "/"
                            : ""
                          : ""
                      }
                      ${
                        item.SerialNumber
                          ? item.IsVirtual === "1"
                            ? "Virtual - "
                            : ""
                          : ""
                      }
                      ${
                        item.SerialNumber
                          ? item.IsPhantom === "1"
                            ? "Phantom - "
                            : ""
                          : ""
                      }
                      ${
                        item.SerialNumber
                          ? item.Warehouse !== item.SerialStatus
                            ? item.SerialStatus
                            : ""
                          : ""
                      }`
                      : item.InternalNote && item.InternalNote !== ""
                      ? `Notes: ${item.InternalNote}`
                      : includeLocation
                      ? `${
                          item.SerialNumber
                            ? item.Warehouse && item.Warehouse === "MEMO"
                              ? `${item.Warehouse} - ${item.StatusCustomer}`
                              : `${item.Warehouse ? `${item.Warehouse}` : ``}`
                            : ""
                        }
                      ${
                        item.SerialNumber
                          ? item.Warehouse !== item.SerialStatus &&
                            item.Warehouse &&
                            item.SerialStatus
                            ? "/"
                            : ""
                          : ""
                      }
                      ${
                        item.SerialNumber
                          ? item.IsVirtual === "1"
                            ? "Virtual - "
                            : ""
                          : ""
                      }
                      ${
                        item.SerialNumber
                          ? item.IsPhantom === "1"
                            ? "Phantom - "
                            : ""
                          : ""
                      }
                      ${
                        item.SerialNumber
                          ? item.Warehouse !== item.SerialStatus
                            ? `${
                                item.SerialStatus ? `${item.SerialStatus}` : ``
                              }`
                            : ""
                          : ""
                      }`
                      : ``}
                  </div>
                ) : (
                  ``
                )}
                {(item.transformType === "JewelrySerial" ||
                  !item.transformType) &&
                includeStoneValues ? (
                  <div className="stone_details">
                    <p style={{margin:'0px',padding:'0px'}} >{item.CenterShape &&
                      `Center Details:
                      ${(item.CenterCaratWeight && item.CenterCaratWeight + " cts") || ""}
                      ${item.CenterShape || ""} ${(item.CenterColor &&
                        item.CenterColor + " /") ||
                        ""}
                      ${(item.CenterClarity && item.CenterClarity + " /") ||
                        ""} ${item.CenterCut || ""} ${item.CenterEnhancement ||
                        ""} ${(item.CenterOrigin && item.CenterOrigin + " - #") ||
                        ""}  ${item.CenterStoneNbr || ""}`
                    }</p>
                    {item.DiamondCarats ||
                    item.Color ||
                    item.Clarity ||
                    item.ColorCarats
                      ?
                      `${
                          item.DiamondCarats
                            ? `
                            ${item.DiamondCarats} carats,`
                            : ""
                        }
                      ${item.Color ? ` ${item.Color} color,` : ""}
                      ${item.Clarity ? ` ${item.Clarity} clarity.` : ""} 
                      ${(item.ColorCarats && item.ColorCarats > 0) ? `${item.ColorCarats} carats.` : ""}`
                      : ``}
                  </div>
                ) : (
                  ``
                )}

                <div className="item_price">
                  {basketDetails.includeRetail &&
                  (item.priceVisibility === "Default" ||
                    item.priceVisibility === "Hide Wholesale Price" ||
                    !item.priceVisibility) &&
                  basketDetails.includeWholesale &&
                  (item.priceVisibility === "Default" ||
                    item.priceVisibility === "Hide Retail Price" ||
                    !item.priceVisibility) &&
                  item.RetailPrice &&
                  parseInt(item.RetailPrice) > 0 &&
                  item.WholesalePrice &&
                  parseInt(item.WholesalePrice) > 0
                    ? `${
                        priceLabel === "Price" ? "Retail" : "MSRP"
                      }: ${currencyFormatter.format(`${item.RetailPrice}`, {
                        code: "USD",
                        precision: 0,
                      })} | Wholesale: ${(item.WholesalePrice &&
                        currencyFormatter.format(`${item.WholesalePrice}`, {
                          code: "USD",
                          precision: 0,
                        })) ||
                        "$0"}`
                    : basketDetails.includeWholesale &&
                      (item.priceVisibility === "Default" ||
                        item.priceVisibility === "Hide Retail Price" ||
                        !item.priceVisibility) &&
                      item.WholesalePrice &&
                      parseInt(item.WholesalePrice) > 0
                    ? `Wholesale : ${(item.WholesalePrice &&
                        currencyFormatter.format(`${item.WholesalePrice}`, {
                          code: "USD",
                          precision: 0,
                        })) ||
                        "$0"} `
                    : basketDetails.includeRetail &&
                      (item.priceVisibility === "Default" ||
                        item.priceVisibility === "Hide Wholesale Price" ||
                        !item.priceVisibility) &&
                      item.RetailPrice &&
                      parseInt(item.RetailPrice) > 0
                    ? `${
                        priceLabel === "Price" ? "Retail" : "MSRP"
                      }: ${(item.RetailPrice &&
                        currencyFormatter.format(`${item.RetailPrice}`, {
                          code: "USD",
                          precision: 0,
                        })) ||
                        "$0"}`
                    : ``}{" "}
                  <br />
                  {item.DiaVideoLink ||
                  (item.SerialVideoLink &&
                    item.transformType === "GemstoneSerial") ? (
                    <a
                      href={
                        item.DiaVideoLink
                          ? item.DiaVideoLink
                          : item.SerialVideoLink
                      }
                      target="_blank"
                    >
                      (view video)
                    </a>
                  ) : (
                    ``
                  )}{" "}
                  {/* {basketDetails.includeLinks === "Web or Internal Imagery" &&
                  item.webProductURL &&
                  item.webProductURL !== "" &&
                  item.webProductURL !== null &&
                  ((item.linkVisibility && item.linkVisibility === "Default") ||
                    !item.linkVisibility) ? (
                    <a href={item.webProductURL}>view online</a>
                  ) : basketDetails.includeLinks === "ONLY Web Imagery" &&
                    item.hasWebImage &&
                    item.hasWebImage === "1" &&
                    item.webProductURL &&
                    item.webProductURL !== "" &&
                    item.webProductURL !== null &&
                    ((item.linkVisibility &&
                      item.linkVisibility === "Default") ||
                      !item.linkVisibility) ? (
                    <a href={item.webProductURL}>view online</a>
                  ) : (
                    ``
                  )}*/}
                  {(includeGIA === "Yes" || includeKTDShapes === "Yes") && (url || item.KTDpdfurl)  && (
                    <a
                      href={
                        !["", null, undefined].includes(item.KTDpdfurl) &&
                        includeKTDShapes === "Yes"
                          ? item.KTDpdfurl
                          : url && includeGIA === "Yes"
                          ? url
                          : ""
                      }
                      target="_blank"
                    >
                      (view report)
                    </a>
                  )}
                </div>
                {item.assetOrmemo && includeAssetOrMemo ? (
                  item.assetOrmemo === "A" ? (
                    <div>Asset</div>
                  ) : item.assetOrmemo === "M" ? (
                    <div>Memo</div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            );
          })}
      </div>
      {cartDetails.map((item, index) => {
        let KTDReports;
        let GIAReports;
        if (includeKTDShapes === "Yes" && item.KTDjpgurl1 && item.KTDjpgurl2) {
          let ktdUrls = [];
          if (item.KTDjpgurl1.includes("|")) {
            ktdUrls = item.KTDjpgurl1.split("|");
          } else {
            ktdUrls = [item.KTDjpgurl1];
          }
          ktdUrls.push(item.KTDjpgurl2);
          console.log({ serialNo: item.SerialNumber, ktdUrls });

          // return
          KTDReports = ktdUrls.map((src, i) => (
            <>
              <div
                key={i}
                style={{
                  height: "11in",
                  // marginTop: "-1in",
                  // marginLeft: "-0.75in",
                  // marginRight: "-0.75in",
                }}
              >
                <img
                  src={src}
                  width="100%"
                  onError={(event) => {
                    // console.log("event :", event.target.parentNode);
                    event.target.parentNode.style.display = "none";
                  }}
                />
              </div>
              {index === cartDetails.length - 1 && includeSummary ? (
                <div ref={summaryElement} style={{ width: "505px" }}></div>
              ) : (
                <></>
              )}
            </>
          ));
        }
        if (includeGIA === "Yes" && item.ReportJpgUrls) {
          let url;
          if (item.ReportJpgUrls.includes("|")) {
            if (item.ReportJpgUrls.charAt(0) === " ") {
              url = item.ReportJpgUrls.replace(/ /, "").split("|");
            } else {
              url = item.ReportJpgUrls.split("|");
            }
          } else {
            url = [item.ReportJpgUrls];
          }
          console.log({ serialNo: item.SerialNumber, url });
          // return
          GIAReports = url.map((src, i) => (
            <>
              <div className="Item_GIA" key={i} style={{ height: "11in" }}>
                <img
                  src={src}
                  width="100%"
                  onError={(event) => {
                    // console.log("event :", event.target.parentNode);
                    event.target.parentNode.style.display = "none";
                  }}
                />
              </div>
              {index === cartDetails.length - 1 && includeSummary ? (
                <div ref={summaryElement} style={{ width: "505px" }}></div>
              ) : (
                <></>
              )}
            </>
          ));
        }
        if (index === cartDetails.length - 1 && includeSummary) {
          return (
            <>
              {KTDReports}
              {GIAReports}
              <div
                ref={summaryElement}
                style={{ width: "505px", margin: "0.55in auto" }}
              ></div>
            </>
          );
        } else {
          return (
            <>
              {KTDReports}
              {GIAReports}
            </>
          );
        }
      })}
    </div>
  );
}
