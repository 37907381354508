import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import currencyFormatter from "currency-formatter";
import CustomerSearch from "../CustomerSearch";
import ContactSearch from "../ContactSearch";
import { setBasketFormInput, setAssetOrMemo } from "../../../actions/index";
import QuickAddProduct from "../QuickAddProduct";
import { Collapse } from "react-collapse";

const mapStateToProps = (state) => {
  return {
    basketInputObj: state.basketInputChange,
  };
};

class BasketForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items,
      totalItems: 0,
      wholesalePrice: 0,
      retailPrice: 0,
      retailCount: 0,
      wholesaleCount: 0,
      noOfMemoItems: 0,
      noOfAssetItems: 0,
      memoLineTotal: 0,
      assetLineTotal: 0,
      formCollapsed: true,
      basketType: props.basketInputObj.basketType,
      desc: props.basketInputObj.desc,
      internalNotes: props.basketInputObj.internalNotes,
      occasion: props.basketInputObj.occasion,
      basketInputs: props.basketInputObj,
      customerClass: props.basketInputObj.customer.CustomerClass || "",
      user: "",
    };
  }
  componentDidMount() {
    this.setSummaryStates(this.props.items, this.props.basketInputObj);
    // let user;

    // // console.log("items : ", this.state.items);
    // if (window.parent.document.getElementById("btnUserMenu")) {
    //   user = window.parent.document
    //     .getElementById("btnUserMenu")
    //     .querySelector(".user-name").textContent;
    // } else {
    //   user = null;
    //   // user = "Sayyed, Sahil";
    //   // console.log("user :", user);
    // }
    // this.setState({ user: user });
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.items !== nextProps.items) {
      this.setState({ items: nextProps.items });
      this.setSummaryStates(nextProps.items, nextProps.basketInputObj);
    }
    if (
      this.state.basketInputs !== nextProps.basketInputObj ||
      this.state.basketInputs.assetOrmemo !==
        nextProps.basketInputObj.assetOrmemo
    ) {
      // console.log("nextprops.basketInputObj: ", nextProps.basketInputObj);
      // console.log("Inside state update");
      this.setState({
        basketInputs: nextProps.basketInputObj,
        basketType: nextProps.basketInputObj.basketType,
        desc: nextProps.basketInputObj.desc,
        internalNotes: nextProps.basketInputObj.internalNotes,
        occasion: nextProps.basketInputObj.occasion,
        customerClass: nextProps.basketInputObj.customer.CustomerClass,
      });
      this.setSummaryStates(nextProps.items, nextProps.basketInputObj);
    }
  }
  setSummaryStates(items, basketInputObj) {
    let { basketInputs, customerClass } = this.state;
    // let { basketInputObj } = this.props;
    // console.log("items summary", items);
    // console.log("basketInputs: ", basketInputs);
    // console.log("basketInputObj: ", basketInputObj);
    var retailPrice = 0,
      wholesalePrice = 0,
      retailCount = 0,
      wholesaleCount = 0,
      noOfMemoItems = 0,
      noOfAssetItems = 0,
      memoLineTotal = 0,
      assetLineTotal = 0;
    items.map((el) => {
      retailPrice += +el.RetailPrice * el.quantity;
      wholesalePrice += +el.WholesalePrice * el.quantity;
      parseInt(el.RetailPrice, 10) === parseFloat(0) || el.RetailPrice === null
        ? retailCount++
        : retailCount;
      parseInt(el.WholesalePrice, 10) === parseFloat(0) ||
      el.WholesalePrice === null
        ? wholesaleCount++
        : wholesaleCount;
      el.assetOrmemo === "M" ||
      (!el.assetOrmemo && basketInputObj.assetOrmemo === "M")
        ? noOfMemoItems++
        : noOfMemoItems;
      el.assetOrmemo === "A" ||
      (!el.assetOrmemo && basketInputObj.assetOrmemo === "A")
        ? noOfAssetItems++
        : noOfAssetItems;
      customerClass === "RETAIL" &&
      (el.assetOrmemo === "M" ||
        (!el.assetOrmemo && basketInputObj.assetOrmemo === "M"))
        ? (memoLineTotal += +el.RetailPrice)
        : memoLineTotal;
      customerClass === "RETAIL" &&
      (el.assetOrmemo === "A" ||
        (!el.assetOrmemo && basketInputObj.assetOrmemo === "A"))
        ? (assetLineTotal += +el.RetailPrice)
        : assetLineTotal;
      customerClass !== "RETAIL" &&
      (el.assetOrmemo === "M" ||
        (!el.assetOrmemo && basketInputObj.assetOrmemo === "M"))
        ? (memoLineTotal += +el.WholesalePrice)
        : memoLineTotal;
      customerClass !== "RETAIL" &&
      (el.assetOrmemo === "A" ||
        (!el.assetOrmemo && basketInputObj.assetOrmemo === "A"))
        ? (assetLineTotal += +el.WholesalePrice)
        : assetLineTotal;

      return {
        retailPrice,
        wholesalePrice,
        retailCount,
        wholesaleCount,
        noOfAssetItems,
        noOfMemoItems,
        memoLineTotal,
        assetLineTotal,
      };
    });
    // console.log(memoLineTotal, assetLineTotal, noOfAssetItems, noOfMemoItems);
    this.setState({
      totalItems: items.length,
      retailPrice,
      wholesalePrice,
      retailCount,
      wholesaleCount,
      noOfAssetItems,
      noOfMemoItems,
      memoLineTotal,
      assetLineTotal,
    });
  }

  render() {
    let {
      retailPrice,
      wholesalePrice,
      totalItems,
      desc,
      internalNotes,
      occasion,
      basketType,
      basketInputs,
      customerClass,
      retailCount,
      wholesaleCount,
      formCollapsed,
      noOfAssetItems,
      noOfMemoItems,
      memoLineTotal,
      assetLineTotal,
      items,
    } = this.state;
    let { userLoggedIn } = this.props;
    // console.log("basketType ", this.state);
    return (
      <div className="basket__form__section">
        <div className="collapse__form">
          <button
            onClick={() => this.setState({ formCollapsed: !formCollapsed })}
          >
            {formCollapsed ? <span>&#8722;</span> : <span>&#43;</span>}
          </button>
        </div>
        <Collapse isOpened={formCollapsed}>
          <div className="basket__details__form">
            <div className="basket__input__wrapper">
              {/* <div className="basket__input type__input">
                <label>Basket Type:</label>
                <select
                  name="type"
                  id="basket_type"
                  value={basketType}
                  onChange={(e) =>
                    this.props.setBasketFormInput({
                      basketType: e.target.value,
                    })
                  }
                >
                  <option value="Retail Proposal">Retail Proposal</option>
                  {/* <option value="Wholesale Proposal">Wholesale Proposal</option> 
                </select>
              </div> */}
              <div className="basket__input type__input">
                <label>* Description:</label>
                <input
                  type="text"
                  placeholder="Enter Description"
                  value={!desc ? "" : desc}
                  onChange={(e) => {
                    this.props.setBasketFormInput({ desc: e.target.value });
                  }}
                />
              </div>
              <div className="basket__input internal-notes__input">
                <label>Internal Notes:</label>
                <input
                  type="text"
                  placeholder="Enter Internal Notes"
                  value={!internalNotes ? "" : internalNotes}
                  onChange={(e) => {
                    this.props.setBasketFormInput({
                      internalNotes: e.target.value,
                    });
                    // this.setSummaryStates({});
                  }}
                />
              </div>
              <CustomerSearch />
              <ContactSearch />
              <div className="basket__input occasion__input">
                <label>Occasion:</label>
                <select
                  name="occasion"
                  id="basket_occasion"
                  value={occasion}
                  onChange={(e) => {
                    this.props.setBasketFormInput({ occasion: e.target.value });
                  }}
                >
                  <option value="default" disabled>
                    Select Occasion
                  </option>
                  <option value="ANNIV">Anniversary</option>
                  <option value="BABY">Baby Celebration</option>
                  <option value="BIRTHDAY">Birthday</option>
                  <option value="ENGAGE">Engagement</option>
                  <option value="HOLIDAY">Holiday</option>
                  <option value="JUSTBEC">Just Because</option>
                  <option value="MOTHDAY">Mothers Day</option>
                  <option value="OTHER">Other Celebration</option>
                  <option value="UNKNOWN">Unknown</option>
                  <option value="VALDAY">Valentines Day</option>
                  <option value="WEDDING">Wedding</option>
                  <option value="GIFT4ANOTH">Gift for Friend</option>
                  <option value="GRADUATION">Graduation</option>
                  <option value="WHOLESALE">Wholesale Proposal</option>
                </select>
              </div>
            </div>
            <div className="summary__private__wrapper">
              <div className="basket__summary">
                <h4>Summary</h4>
                <div className="basket__summary__details">
                  {customerClass === "WHOLESALE" &&
                  basketInputs.showWholesale ? (
                    <>
                      {" "}
                      <div className="summary__info summary__total-items">
                        <span className="summary__label">
                          # of Asset Items:{" "}
                        </span>
                        <span className="summary__value">{noOfAssetItems}</span>
                      </div>
                      <div className="summary__info summary__total-items">
                        <span className="summary__label">
                          # of Memo Items:{" "}
                        </span>
                        <span className="summary__value">{noOfMemoItems}</span>
                      </div>
                      <div className="summary__info ">
                        <span className="summary__label">
                          Asset Line Total:{" "}
                        </span>
                        <span className="summary__value">
                          {currencyFormatter.format(`${assetLineTotal}`, {
                            code: "USD",
                            precision: 0,
                          })}
                        </span>
                      </div>
                      <div className="summary__info ">
                        <span className="summary__label">
                          Memo Line Total:{" "}
                        </span>
                        <span className="summary__value">
                          {currencyFormatter.format(`${memoLineTotal}`, {
                            code: "USD",
                            precision: 0,
                          })}
                        </span>
                      </div>
                    </>
                  ) : (
                    ``
                  )}
                  <div className="summary__info summary__total-items">
                    <span className="summary__label">Total Items: </span>
                    <span className="summary__value">{totalItems}</span>
                  </div>
                  <div className="summary__info summary__wo-price">
                    <span className="summary__label"># w/o Price: </span>
                    <span className="summary__value">
                      {customerClass === "RETAIL"
                        ? retailCount
                        : basketInputs.showWholesale
                        ? wholesaleCount
                        : ``
                      // wholesaleCount
                      }
                    </span>
                  </div>
                  <div className="summary__info summary__retail-price">
                    <span className="summary__label">Total Retail: </span>
                    <span className="summary__value">
                      {currencyFormatter.format(`${retailPrice}`, {
                        code: "USD",
                        precision: 0,
                      })}
                    </span>
                  </div>
                  {customerClass !== "RETAIL" && basketInputs.showWholesale ? (
                    <div className="summary__info summary__wholesale-price">
                      <span className="summary__label">Total Wholesale: </span>
                      <span className="summary__value">
                        {currencyFormatter.format(`${wholesalePrice}`, {
                          code: "USD",
                          precision: 0,
                        })}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {customerClass && customerClass !== "RETAIL" ? (
                <div className="setAssetOrMemo">
                  <label>Select Asset OR Memo:</label>
                  {/* <select
                    defaultValue={basketInputs.assetOrmemo || ""}
                    onChange={(e) => {
                      this.props.setBasketFormInput({
                        assetOrmemo: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled>
                      Select Asset OR Memo
                    </option>
                    <option value="A">Asset</option>
                    <option value="M">Memo</option>
                  </select> */}
                  <div className="asset-memo__toggle">
                    <input
                      type="radio"
                      id="asset"
                      name="asset-memo"
                      value="A"
                      checked={basketInputs.assetOrmemo === "A" ? true : false}
                      onChange={(e) => {
                        // console.log("value : ", e.target.value);
                        let value = e.target.value;
                        // this.setSummaryStates(items);
                        this.props.setBasketFormInput({
                          assetOrmemo: e.target.value,
                        });
                        // this.props.items.map((el) => {
                        //   this.props.setAssetOrMemo({
                        //     item: el,
                        //     value: value,
                        //   });
                        // });
                      }}
                    />
                    <label htmlFor="asset">Asset</label>
                  </div>
                  <div className="asset-memo__toggle">
                    <input
                      type="radio"
                      id="memo"
                      name="asset-memo"
                      value="M"
                      checked={basketInputs.assetOrmemo === "M" ? true : false}
                      onChange={(e) => {
                        // console.log("value : ", e.target.value);
                        let value = e.target.value;
                        this.props.setBasketFormInput({
                          assetOrmemo: e.target.value,
                        });
                        // this.setSummaryStates(items);
                        // this.props.items.map((el) => {
                        //   this.props.setAssetOrMemo({
                        //     item: el,
                        //     value: value,
                        //   });
                        // });
                      }}
                    />
                    <label htmlFor="memo">Memo</label>
                  </div>
                </div>
              ) : (
                ``
              )}
              <div
                className="make__private__checkbox"
                style={{
                  marginTop:
                    customerClass && customerClass !== "RETAIL"
                      ? "10px"
                      : "27px",
                }}
              >
                {userLoggedIn === basketInputs.basketUserDetails ||
                basketInputs.orderNbr === "New" ? (
                  <>
                    <input
                      type="checkbox"
                      id="Make-Private"
                      checked={basketInputs.makePrivate}
                      onChange={(e) =>
                        this.props.setBasketFormInput({
                          makePrivate: e.target.checked,
                        })
                      }
                      // disabled={false}
                      // disabled={
                      //   this.state.user ===
                      //   this.props.basketInputObj.basketUserDetails
                      //     ? false
                      //     : true
                      // }
                    />
                    <label htmlFor="Make-Private">Make Private</label>
                  </>
                ) : (
                  ``
                )}
                {userLoggedIn === basketInputs.basketUserDetails ||
                basketInputs.orderNbr === "New" ? (
                  <>
                    <input
                      type="checkbox"
                      id="Allow-Edit"
                      checked={basketInputs.edit}
                      // disabled={
                      //   userLoggedIn === this.props.basketInputObj.basketUserDetails
                      //     ? false
                      //     : true
                      // }
                      onChange={(e) =>
                        this.props.setBasketFormInput({
                          edit: e.target.checked,
                        })
                      }
                      // disabled={false}
                      // disabled={
                      //   this.state.user ===
                      //   this.props.basketInputObj.basketUserDetails
                      //     ? false
                      //     : true
                      // }
                    />
                    <label htmlFor="Allow-Edit">Allow Editing</label>
                  </>
                ) : (
                  ``
                )}
                {(userLoggedIn === basketInputs.basketUserDetails &&
                  basketInputs.status !== "Archived") ||
                basketInputs.orderNbr === "New" ? (
                  <>
                    {/* <input
                      type="checkbox"
                      id="Archived"
                      checked={
                        basketInputs.status === "Archived" ? true : false
                      }
                      onChange={(e) =>
                        this.props.setBasketFormInput({
                          status: e.target.checked ? "Archived" : "",
                        })
                      }
                      // disabled={false}
                      // disabled={
                      //   this.state.user ===
                      //   this.props.basketInputObj.basketUserDetails
                      //     ? false
                      //     : true
                      // }
                    />
                    <label htmlFor="Archived" onClick={}>Archive</label> */}
                    <button
                      onClick={() => {
                        this.props.setBasketFormInput({ status: "Archived" });
                        this.props.handleSave("Archived");
                      }}
                    >
                      Archive
                    </button>
                  </>
                ) : userLoggedIn === basketInputs.basketUserDetails &&
                  basketInputs.status !== "Active" ? (
                  <label
                    href=""
                    onClick={() => {
                      this.props.setBasketFormInput({
                        status: "Active",
                      });
                      this.props.handleSave("Active");
                    }}
                  >
                    Make Active
                  </label>
                ) : (
                  ``
                )}
              </div>
            </div>
          </div>
        </Collapse>
        <div className="basket__quick__add">
          <QuickAddProduct
            showQuickAddBulkProductModal={
              this.props.showQuickAddBulkProductModal
            }
          />
          {/* <div className="price__checkbox__wrapper">
            <div className="price__toggle retail__checkbox">
              <label htmlFor="retail_check">Include Retail Price:</label>
              <input
                type="checkbox"
                id="retail_check"
                checked={basketInputs.includeRetail}
                onChange={(e) =>
                  this.props.setBasketFormInput({
                    includeRetail: e.target.checked,
                  })
                }
              />
            </div>
            <div className="price__toggle wholesale__checkbox">
              <label htmlFor="wholesale_check">Include Wholesale Price:</label>
              <input
                type="checkbox"
                id="wholesale_check"
                checked={basketInputs.includeWholesale}
                onChange={(e) =>
                  this.props.setBasketFormInput({
                    includeWholesale: e.target.checked,
                  })
                }
              />
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setBasketFormInput,
      setAssetOrMemo,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BasketForm);
