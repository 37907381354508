import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import Appbase from "appbase-js";
import {
  AppbaseAppUrl,
  AppbaseCredentials,
  AppbaseCoverApp,
} from "../../../utils/constants";
import { toast } from "react-toastify";
import { toggleLoader } from "../../../actions";
import AWS from "aws-sdk";
import S3 from "react-aws-s3";
import { uploadFile } from "react-s3";

const S3_BUCKET = "kwfl-elastic-search-assets";
const REGION = "us-east-1";
const ACCESS_KEY = "AKIAUJCBU7UIY6AIQD5H";
const SECRET_ACCESS_KEY = "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";

const config1 = {
  bucketName: S3_BUCKET,
  region: REGION,
  dirName: "logos",
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};
const config2 = {
  bucketName: S3_BUCKET,
  region: REGION,
  dirName: "pdf-covers",
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

// const config1 = {
//   bucketName: "kwfl-elastic-search-assets",
//   dirName: "logos" /* optional */,
//   region: "us-east-1",
//   accessKeyId: "AKIAUJCBU7UIY6AIQD5H",
//   secretAccessKey: "JRmSddrmo1EMDQBRV1mVjezgqxRrOifHJfGocMxa",
//   // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
// };
// const config2 = {
//   bucketName: "kwfl-elastic-search-assets",
//   dirName: "pdf-covers" /* optional */,
//   region: "us-east-1",
//   accessKeyId: "AKIAUJCBU7UIY6AIQD5H",
//   secretAccessKey: "JRmSddrmo1EMDQBRV1mVjezgqxRrOifHJfGocMxa",
//   // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
// };

// const ReactS3ClientLogo = new S3(config1);
// const ReactS3ClientImage = new S3(config2);

// const S3_BUCKET = "YOUR_BUCKET_NAME_HERE";
// const REGION = "us-east-1";

// AWS.config.update({
//   accessKeyId: "AKIAUJCBU7UIY6AIQD5H",
//   secretAccessKey: "JRmSddrmo1EMDQBRV1mVjezgqxRrOifHJfGocMxa",
// });

// const myBucket = new AWS.S3({
//   params: { Bucket: S3_BUCKET },
//   region: REGION,
// });

export default function CoverEdit(props) {
  let { show, handleCoverEdit, coverDetails } = props;
  const [state, setState] = useState({
    id: "",
    coverName: "",
    coverCategory: "",
    coverDisplayOrder: "",
    coverStatus: "Active",
    // coverDateCreated: "",
    coverDescription: "",
    coverTitle: "",
    coverLogo: "",
    coverImage: "",
    // progress: 0,
    logoFile: "",
    imageFile: "",
  });
  const Dispatch = useDispatch();

  // const updatedStateValue = useMemo(
  //   () => {
  //     return setTemplateDetails();
  //   },
  //   [templateDetails]
  // );

  const handleCoverReset = () => {
    setState({
      id: "",
      coverName: "",
      coverCategory: "",
      coverDisplayOrder: "",
      coverStatus: "Active",
      // coverDateCreated: "",
      coverDescription: "",
      coverTitle: "",
      coverLogo: "",
      coverImage: "",
      // logoFile: "",
      // imageFile: "",
    });

    document.getElementById("ImageFile").value = "";
    document.getElementById("LogoFile").value = "";
  };

  const setCoverDetails = () => {
    setState({
      ...state,
      id: coverDetails.id ? coverDetails.id : "",
      coverName: coverDetails.Name ? coverDetails.Name : "",
      coverCategory: coverDetails.Category ? coverDetails.Category : "",
      coverDisplayOrder: coverDetails.DisplayOrder
        ? coverDetails.DisplayOrder
        : "",
      // coverDateCreated: coverDetails.DateCreated
      //   ? coverDetails.DateCreated
      //   : "",
      coverStatus: coverDetails.Status ? coverDetails.Status : "Active",
      coverDescription: coverDetails.Description
        ? coverDetails.Description
        : "",
      coverTitle: coverDetails.Title ? coverDetails.Title : "",
      coverLogo: coverDetails.Logo ? coverDetails.Logo : "",
      coverImage: coverDetails.Image ? coverDetails.Image : "",
    });
  };

  const addCover = async () => {
    Dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    const appbaseRef = Appbase({
      app: AppbaseCoverApp,
      url: AppbaseAppUrl,
      credentials: AppbaseCredentials,
    });
    let currentdate = new Date();
    let dateTime =
      currentdate.getFullYear() +
      "" +
      (currentdate.getMonth() + 1) +
      "" +
      currentdate.getDate() +
      "" +
      currentdate.getHours() +
      "" +
      currentdate.getMinutes() +
      "" +
      currentdate.getSeconds();
    let payload = {
      DateCreated: dateTime,
      Category: state.coverCategory,
      Name: state.coverName,
      DisplayOrder: state.coverDisplayOrder,
      Description: state.coverDescription,
      Status: state.coverStatus,
      Title: state.coverTitle,
      Logo: state.coverLogo,
      Image: state.coverImage,
    };

    let response = await appbaseRef
      .index({
        ...(![undefined, null, ""].includes(state.id) && { id: state.id }),
        body: payload,
      })
      .then(function (res) {
        // console.log("Template added successfully: ", res);
        return res;
      })
      .catch(function (err) {
        console.log("Cover error: ", err);
        toast.error(`Error in adding cover`, {
          // position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
          theme: "colored",
        });
        return err;
      });
    if (
      response &&
      response.result &&
      ["created", "updated"].includes(response.result)
    ) {
      props.handleCoverRefresh({
        id: response._id,
        DateCreated: payload.DateCreated,
        Category: payload.Category,
        Name: payload.Name,
        DisplayOrder: payload.DisplayOrder,
        Description: payload.Description,
        Status: payload.Status,
        Title: payload.Title,
        Logo: payload.Logo,
        Image: payload.Image,
      });
      Dispatch(
        toggleLoader({
          isLoading: false,
        })
      );
      toast.success(
        `${
          state.id ? `Cover updated successfully` : `Cover Added successfully`
        }`,
        {
          // position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
          theme: "colored",
        }
      );
      handleCoverEdit(false, "");
    } else {
      toast.error(`Error in adding cover`, {
        // position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
        theme: "colored",
      });
    }
  };

  const handleLogoFileInput = async (e) => {
    Dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    // console.log("e: ", e);
    // console.log("e.target: ", e.target);
    // console.log("e.target.files: ", e.target.files);

    let file = e.target.files[0];
    // setState((prevState) => {
    //   return { ...prevState, logoFile: e.target.files[0] };
    // });
    if (e.target.files[0]) {
      // await ReactS3ClientLogo.uploadFile(file, file.name)
      //   .then((data) => console.log(data))
      //   .catch((err) => console.error(err));
      await uploadFile(file, config1)
        .then((data) => {
          // console.log(data);
          setState((prevState) => {
            return { ...prevState, coverLogo: data.location };
          });
          Dispatch(
            toggleLoader({
              isLoading: false,
            })
          );
        })
        .catch((err) => {
          console.error(err);
          Dispatch(
            toggleLoader({
              isLoading: false,
            })
          );
        });
    }
    // uploadFile(e.target.files[0]);
  };
  const handleImageFileInput = async (e) => {
    Dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    // console.log("e: ", e);
    // console.log("e.target: ", e.target);
    // console.log("e.target.files: ", e.target.files);
    let file = e.target.files[0];
    // setState((prevState) => {
    //   return { ...prevState, imageFile: e.target.files[0] };
    // });
    if (e.target.files[0]) {
      // await ReactS3ClientImage.uploadFile(file, file.name)
      //   .then((data) => console.log(data))
      //   .catch((err) => console.error(err));
      await uploadFile(file, config2)
        .then((data) => {
          // console.log(data);
          setState((prevState) => {
            return { ...prevState, coverImage: data.location };
          });
          Dispatch(
            toggleLoader({
              isLoading: false,
            })
          );
        })
        .catch((err) => {
          console.error(err);
          Dispatch(
            toggleLoader({
              isLoading: false,
            })
          );
        });
    }
    // uploadFile(e.target.files[0]);
  };

  // const uploadFile = async (file) => {
  //   // const params = {
  //   //   ACL: "public-read",
  //   //   Body: file,
  //   //   Bucket: S3_BUCKET,
  //   //   Key: file.name,
  //   // };

  //   // await myBucket
  //   //   .putObject(params)
  //   //   .on("httpUploadProgress", (evt) => {
  //   //     // setProgress(Math.round((evt.loaded / evt.total) * 100));
  //   //     console.log(evt);
  //   //   })
  //   //   .send((err) => {
  //   //     if (err) console.log(err);
  //   //   });
  // };

  useEffect(() => {
    setCoverDetails();
  }, [coverDetails]);

  return (
    <Modal
      show={show}
      className="template-edit-modal"
      onHide={() => {
        handleCoverEdit(false, "");
        handleCoverReset();
      }}
    >
      <Modal.Header closeButton>Cover Details</Modal.Header>
      <Modal.Body>
        <div className="template_edit">
          <div className="template_input">
            <label> Name:</label>
            <input
              type="text"
              placeholder="Cover Name..."
              value={state.coverName}
              onChange={(e) => {
                setState({ ...state, coverName: e.target.value });
              }}
            />
          </div>
          <div className="template_input">
            <label> Category:</label>
            <input
              type="text"
              placeholder="Cover Category..."
              value={state.coverCategory}
              onChange={(e) => {
                setState({ ...state, coverCategory: e.target.value });
              }}
            />
          </div>
          <div className="template_input">
            <label>Display Order:</label>
            <input
              type="text"
              placeholder="Display Order..."
              value={state.coverDisplayOrder}
              onChange={(e) => {
                setState({ ...state, coverDisplayOrder: e.target.value });
              }}
            />
          </div>
          <div className="template_input">
            <label>Status:</label>
            <select
              value={state.coverStatus}
              onChange={(e) =>
                setState({ ...state, coverStatus: e.target.value })
              }
            >
              <option value="Active">Active</option>
              <option value="Pending">Pending</option>
            </select>
            {/* <input
              type="text"
              placeholder="Status..."
              value={state.coverStatus}
              onChange={(e) => {
                setState({ ...state, coverStatus: e.target.value });
              }}
            /> */}
          </div>
          <div className="template_input">
            <label>Description:</label>
            <input
              type="text"
              placeholder="Description ..."
              value={state.coverDescription}
              onChange={(e) => {
                setState({ ...state, coverDescription: e.target.value });
              }}
            />
          </div>
          <div className="template_input">
            <label>Title:</label>
            <input
              type="text"
              placeholder="Title ..."
              value={state.coverTitle}
              onChange={(e) => {
                setState({ ...state, coverTitle: e.target.value });
              }}
            />
          </div>
          <div className="template_input">
            <label>Logo:</label>
            <input
              type="text"
              placeholder="Logo ..."
              value={state.coverLogo}
              onChange={(e) => {
                setState({ ...state, coverLogo: e.target.value });
              }}
            />
            <input
              type="file"
              className="form-control"
              onChange={(e) => handleLogoFileInput(e)}
              id="LogoFile"
            />
          </div>
          <div className="template_input">
            <label>Image:</label>
            <input
              type="text"
              placeholder="Image ..."
              value={state.coverImage}
              onChange={(e) => {
                setState({ ...state, coverImage: e.target.value });
              }}
            />
            <input
              type="file"
              className="form-control"
              onChange={(e) => handleImageFileInput(e)}
              id="ImageFile"
            />
          </div>
          <div className="template_input">
            <button onClick={() => addCover()}>Save</button>
            <button onClick={() => handleCoverReset()}>Clear</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
